.trends-table-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.trends-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.trends-table th, .trends-table td {
  padding: 12px;
  border-bottom: 1px solid var(--lighter-grey);
}

.trends-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.ask-anything-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
}

.ask-anything-button:hover {
  background-color: #0056b3;
}

.chat-container {
  margin-top: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.chat-messages {
  max-height: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message.user {
  text-align: right;
  color: #007bff;
}

.chat-message.assistant {
  text-align: left;
  color: var(--darker-grey);
}

.loading {
  text-align: center;
  color: #666;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--lighter-grey);
  border-radius: 4px;
}

.chat-input button {
  background-color: #28a745;
  border-radius: 4px;
}

.chat-input button:hover {
  background-color: #218838;
}
