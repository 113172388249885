.query-traffic-table-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.query-traffic-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.query-traffic-table th, .device-traffic-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.query-traffic-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}
