:root {
  --theme-color: #7C76F5;
  --theme-color-faded: #6a4eb0;
  --theme-color-dark: #553c9a;
  --theme-color-bright: #BC43FF;
  --darker-grey: #333;
  --dark-grey: #555;
  --light-grey: #ccc;
  --lighter-grey: #ddd;
  --border-grey: #E9EBED;
}

/* Reset common margin/padding issues */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

h2 {
  text-align: center;
  color: #4b4b8c;
}

h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--theme-color-dark);
  display: flex;
  justify-content: space-between;
}

p {
  font-size: 14px;
  color: var(--darker-grey);
  font-weight: 500;
}

button {
  background-color: var(--theme-color);
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--theme-color-faded);
}

button:focus-visible {
  outline: 2px solid var(--theme-color);
  outline-offset: 4px;
}

button:disabled {
  background-color: var(--dark-grey);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

/* Ensure inputs, selects, and textareas stretch fully */
input,
select,
textarea {
  width: 100%; /* Full width */
  padding: 10px; /* Internal padding */
  border: 1px solid var(--theme-color); /* Border matching the form block */
  border-radius: 5px; /* Rounded corners */
  font-size: 14px; /* Consistent font size */
  color: var(--darker-grey); /* Text color */
  box-sizing: border-box; /* Include padding in width */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth focus transition */
}

textarea {
  height: 100px;
  background: white;
  line-height: 1.5; /* Improves text readability */
}

/* Highlight inputs on focus */
input:focus,
select:focus,
textarea:focus {
  outline: none; /* Remove default outline */
  border-color: var(--theme-color-dark); /* Highlighted border on focus */
  box-shadow: 0 0 5px rgba(85, 60, 154, 0.5); /* Optional shadow effect */
}

label {
  font-size: 14px; /* Adjust the font size to match the design */
  color: var(--darker-grey); /* Text color */
  display: inline-flex;
  align-items: center; /* Align checkbox and text vertically */
}

/* Main app container */
.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* Content (form) container */
.content {
  flex-shrink: 0;
  width: 350px; /* Fixed width for the CreateAd section */
  padding: 20px;
  background-color: white;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.content.hidden {
  display: none;
}

.content.visible {
  display: flex;
  flex-direction: column;
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}

.main-section {
  flex: 1; /* Takes the remaining space */
  height: auto; /* Allow it to resize based on content */
  display: flex;
  flex-direction: column; /* Ensures stacking of children */
  gap: 20px;
  overflow-y: auto; /* Allow scrolling if content overflows */
  border-left: 1px solid var(--lighter-grey);
  background-color: #EAEAF4;
}

/* Response section styling */
.response-section {
  flex: 1; /* Takes the remaining space */
  height: auto; /* Allow it to resize based on content */
  display: flex;
  flex-direction: column; /* Ensures stacking of children */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

/* Response box */
.response-box {
  flex: 1; /* Allow it to grow and fill available space */
  display: block; /* Block layout for content */
  border-radius: 0; /* Removed rounded corners */
  box-shadow: none; /* Removed the shadow (if you want a flat design) */
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  min-height: 200px; /* Ensure it doesn’t shrink too much */
}

/* Optional: Adjust heading style */
.response-box h3 {
  margin-bottom: 10px;
  color: var(--theme-color-dark);
}

.response-box p {
  font-size: 16px;
}

/* Refine options styling */
.refine-options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.refine-options button {
  margin: 5px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid var(--lighter-grey);
  border-radius: 5px;
}

.refine-options button:hover {
  background-color: var(--lighter-grey);
}

/* Dropdown Header */
.dropdown-header {
  font-size: 18px;
  color: var(--theme-color-dark);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
}

/* Create thread button styling */
.create-thread-button {
  background-color: var(--theme-color-dark);
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: auto;
}

.logout-button {
  background-color: var(--theme-color-dark);
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: #4a3292;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.logout-button:active {
  background-color: #3e287a;
  transform: translateY(0);
}

.full-screen-chat-container {
  position: relative; /* Keep it in the document flow */
  display: flex;
  flex-direction: column;
  height: 100%; /* Full height within the parent container */
  width: 100%; /* Take up the remaining space */
  background-color: white; /* Set background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Adjusted shadow */
  padding: 20px; /* Internal spacing */
  overflow-y: auto; /* Enable scrolling if content exceeds container height */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.user-info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align content to the right */
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--lighter-grey);
  font-size: 16px;
  font-weight: 500;
  color: var(--darker-grey);
  gap: 12px; /* Add spacing between the text and the icon */
  height: 70px
}

.user-info .page-title {
  flex-grow: 1; /* Allow the page-title to take up space on the left */
  text-align: left; /* Align the text of the title to the left */
  font-weight: bold;
  font-size: 24px;
}

/* Reset default styles for the gear wrapper */
.gear-button {
  background: none;
  border: none;
}

.gear-button:focus-visible {
  outline: none;
}

.gear-button:hover {
  background: none;
}

.gear-icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
}

/* A nice transition effect to indicate clickability that stays while menu is open */
.gear-icon:hover, .gear-button:focus .gear-icon {
  transform: rotate(45deg) scale(1.1);
  filter: brightness(1.2) drop-shadow(0 0 2px rgba(0, 123, 255, 0.4));
}

/* Extra effect for accessibility (only visible foe keyboard navigation */
.gear-button:focus-visible .gear-icon {
  outline: 2px solid var(--theme-color);
  outline-offset: 4px;
  border-radius: 50%;
}

.dropdown-settings {
  position: absolute;
  top: 50px;
  right: 10px;
  background: white;
  border: 1px solid var(--lighter-grey);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
}

.dropdown-setting {
  display: block;
  padding: 10px 15px;
  background: none;
  border: none;
  border-radius: inherit; /* relevant for hover effect */
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: var(--darker-grey);
}

.dropdown-setting:hover {
  background-color: #f0f0f0;
}

/* Main response item container */
.response-item {
  --user-info-margin: 20px;
  display: flex;
  flex-direction: column;
  margin: var(--user-info-margin);
}

.response-item.multiple {
  background-color: white;
  border: 1px solid var(--lighter-grey); /* Restored border */
  border-radius: 5px; /* Optional: Adds rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

/* Refine options */
.refine-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.refine-option {
  padding: 8px 12px;
  border: 1px solid var(--lighter-grey);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #f0f0f0;
}

.refine-option:hover {
  background-color: var(--lighter-grey);
}

.response-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px; /* Space between chart and buttons */
}

.response-footer.post-footer {
  flex-direction: column;
  width: 100%;
}

.response-actions {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between buttons */
  padding: 10px;
}

.response-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.chart-container {
  position: relative;
  width: 90px;
  height: 90px;
}

.chart-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 33%;
  height: auto;
}

/* Scale value styling */
.scale-value {
  color: #2F2F2F;
  margin-top: 0;
  text-align: center;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.78569rem; /* 62.857% */
  text-transform: capitalize;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.magic-boost-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

.copy-button,
.edit-button {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.copy-button:hover,
.edit-button:hover {
  background-color: rgba(134, 124, 170, 0.6);
}

.copy-icon,
.edit-icon {
  width: 20px;
  height: 20px;
}

.response-wrapper {
  display: flex;
  align-items: flex-start; /* Aligns chart/buttons to the top of the text */
  justify-content: space-between;
  gap: 2rem; /* Space between the text box and chart/buttons */
}

.multiple .response-wrapper {
  align-items: center; /* Aligns chart/buttons to the center of the text */
}

.response-side {
  display: flex;
  align-items: flex-start; /* Aligns chart/buttons vertically */
  gap: 10px;
}

.response-side.post-side {
  position: sticky;
  top: var(--user-info-margin);
  flex: 4;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--lighter-grey); /* Restored border */
  border-radius: 5px; /* Optional: Adds rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

.response-header {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 0;
}

.download-extension-link {
  --link-color: #0073e6;
  display: inline-block;
  color: var(--link-color); /* Set a blue color for the link */
  text-decoration: none; /* Remove underline */
  font-weight: bold;
  cursor: pointer;
}

.download-extension-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.download-extension-link:focus-visible {
  outline: 2px solid var(--link-color);
  outline-offset: 4px;
  border-radius: 4px;
}

.tone-option {
  display: inline-block;
  padding: 10px;
  margin: 5px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  cursor: pointer;
}

.tone-option.selected {
  background-color: var(--theme-color-bright);
  color: white;
}

.images-section {
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

/* Image gallery styling */
.image-gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.generated-image {
  max-width: 250px;
  max-height: 250px;
  height: auto;
  width: calc(33.33% - 7px);
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.edit-mode {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.edit-textarea {
  height: 100%;
  font-size: 16px; /* Comfortable font size */
  font-family: "Source Sans 3";
  background-color: #f9f9f9; /* Light background for readability */
  resize: vertical; /* Allow vertical resizing */
  outline: none; /* Remove default outline */
  field-sizing: content;
  margin-bottom: 0;
}

/* Add some margin below for spacing */
.edit-container {
  margin-bottom: 20px;
}

/* Buttons in edit-actions */
.edit-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px; /* Space between buttons */
}

.save-edit-button,
.cancel-edit-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px; /* Rounded corners */
}

.cancel-edit-button {
  color: #dc3545;
  background-color: inherit;
  border: 1px solid #dc3545; /* Red for cancel button */
}

.cancel-edit-button:hover {
  color: white;
  background-color: #c82333; /* Darker red on hover */
}

.response-container {
  position: relative; /* Allows absolute positioning inside */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.response-container.post-container {
  flex: 6;
  background-color: white;
  border: 1px solid var(--lighter-grey); /* Restored border */
  border-radius: 5px; /* Optional: Adds rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

.styled-pre {
  flex: 1;
  font-size: 20px;
  color: var(--darker-grey);
  line-height: 1.5;
  background-color: white;
  padding: 10px;
  white-space: pre-wrap;
  border: none;
  border-radius: 0;
  width: 100%;
  font-family: "Source Sans 3";
}

.chart-wrapper canvas {
    fill: var(--theme-color-bright) !important;
}

.default-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%; /* Ensure it takes up space for centering */
}

.logo-image-default {
  width: 150px; /* Adjust size as needed */
  margin-top: 20px;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
}
