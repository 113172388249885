.generate-button-wrapper{
  margin-top: auto;
  padding-top: inherit;
}

.generate-button {
  background: linear-gradient(90deg, var(--theme-color) 0%, var(--theme-color-bright) 50%, var(--theme-color) 100%);
  background-size: 200% 100%;
  background-position: 0% 0%;
  transition: all 0.5s ease; /* Smooth hover effect */
}

.generate-button:hover {
  background-position: 100% 0%;
}

.sparks {
  top: 2px;
  position: relative;
  right: 10px;
}