.radar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.radar-grid {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between rows */
  width: 100%;
  max-width: 2000px;
}

.radar-row {
  display: flex;
  gap: 20px; /* Space between boxes in a row */
  justify-content: space-between;
}

.radar-box,
.radar-box-large {
  flex: 1;
  padding: 15px;
  background-color: white;
  border: 1px solid var(--lighter-grey);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color */
  overflow-y: auto;
  text-overflow: ellipsis;
  white-space: pre-wrap; /* Preserve line breaks */
  word-wrap: break-word;
  min-height: 150px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.radar-box-large {
  max-height: 1400px;
}

.radar-box-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.author-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.author-image-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-grey); /* Gray background for missing images */
  margin-right: 10px;
}

.author-name {
  font-weight: bold;
  font-size: 16px;
}

.radar-box {
  border: 1px solid var(--lighter-grey);
  padding: 15px;
  position: relative;
  transition: transform 0.2s ease-in-out;
  text-decoration: none; /* Remove underline from <a> */
  color: inherit; /* Keep text color unchanged */
}

.radar-box-content {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Source Sans 3";
}

.radar-box-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--lighter-grey);
  font-size: 14px;
  color: var(--dark-grey);
}

.radar-box-footer span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: var(--darker-grey);
}

.radar-box-footer span:first-child {
  margin-left: 0;
}

.radar-buttons {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.go-to-post-button,
.add-to-favorites-button,
.fetch-prospect-posts-button,
.refresh-posts-button,
.save-post-button {
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
}

.post-media-container {
  width: 100%;
  max-width: 600px; /* Adjust this as needed */
  height: 800px;

}

.post-media {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Prevents cropping and ensures full visibility */
}

video.post-media {
  width: 100%;
  height: 100%;
}
