/*!* Container styling *!*/
/*.brand-strategy-container {*/
/*  display: flex;*/
/*  justify-content: center; !* Center the form horizontally *!*/
/*  align-items: center; !* Center the form vertically *!*/
/*  height: 100vh; !* Full viewport height *!*/
/*  width: 100%; !* Full width *!*/
/*  padding: 0; !* Remove any padding to eliminate gaps *!*/
/*  background-color: #fff; !* Background color *!*/
/*  box-sizing: border-box; !* Include padding and border in width calculations *!*/
/*}*/

/*!* Form styling *!*/
/*.brand-strategy-form {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  gap: 20px; !* Add spacing between form elements *!*/
/*  width: 100%; !* Ensure it spans the full width of the container *!*/
/*  max-width: 100%; !* Remove any unnecessary restrictions *!*/
/*  height: 100%; !* Ensure it spans the full height of the container *!*/
/*  padding: 20px; !* Add internal padding for form content *!*/
/*  box-sizing: border-box; !* Include padding in size calculations *!*/
/*  background-color: #f9f9f9; !* Optional: Background color for the form *!*/
/*  border-radius: 8px; !* Add rounded corners *!*/
/*  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); !* Optional: Shadow for aesthetics *!*/
/*  overflow-y: auto; !* Enable scrolling if content overflows *!*/
/*}*/

/*!* Form group styling *!*/
/*.form-group {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  gap: 10px; !* Add spacing between label and input *!*/
/*}*/

/*!* Label styling *!*/
/*label {*/
/*  font-weight: bold;*/
/*}*/

/*!* Input and textarea styling *!*/
/*input,*/
/*textarea {*/
/*  border: 1px solid #ddd;*/
/*  resize: none; !* Prevent textarea resizing *!*/
/*  min-height: 80px; !* Set minimum height for textareas *!*/
/*}*/

/*!* Input with button container *!*/
/*.input-with-button {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  gap: 10px; !* Add spacing between input and button *!*/
/*  width: 100%;*/
/*}*/

/*!* Generate button styling *!*/
/*.generate-button {*/
/*  padding: 8px 15px;*/
/*  background-color: #553c9a;*/
/*  border: none;*/
/*  border-radius: 5px;*/
/*  font-size: 14px;*/
/*  white-space: nowrap; !* Prevent text wrapping *!*/
/*}*/

/*.generate-button:disabled {*/
/*  background-color: #b39ddb;*/
/*}*/

/*!* Save button styling *!*/
/*.save-button {*/
/*  padding: 10px 20px;*/
/*  background-color: #007bff;*/
/*  border: none;*/
/*  border-radius: 5px;*/
/*  align-self: center; !* Center align the save button *!*/
/*  width: 100%; !* Stretch the save button to full width *!*/
/*}*/

/*.save-button:hover {*/
/*  background-color: #0056b3;*/
/*}*/

/*!* Responsive adjustments *!*/
/*@media (max-width: 768px) {*/
/*  .brand-strategy-form {*/
/*    padding: 10px; !* Reduce padding for smaller screens *!*/
/*  }*/
/*}*/
