/* Image style container */
.image-style-container {
  margin-top: 20px;
  text-align: center;
}

.image-style-container h3 {
  font-size: 18px;
  color: var(--theme-color-dark);
  margin-bottom: 10px;
}

/* Image grid styling */
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjusted grid size for responsiveness */
  gap: 10px;
  justify-items: center;
}

/* Individual style option */
.style-option {
  width: 120px; /* Consistent size for the container */
  height: 150px; /* Include room for image and text */
  text-align: center;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Wrapper for the image */
.style-option .image-wrapper {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
  background-color: white; /* Optional: Add a clean background for the image */
  margin-bottom: 5px; /* Space between image and text */
}

/* Image styling */
.style-option img {
  max-width: 100%; /* Ensure image does not overflow horizontally */
  max-height: 100%; /* Ensure image does not overflow vertically */
  object-fit: cover; /* Ensure proper cropping for irregularly sized images */
}

/* Text styling for the style name */
.style-option p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Selected and hover styles */
.style-option.selected {
  border-color: var(--theme-color-dark);
  transform: scale(1.05); /* Slight enlargement to indicate selection */
}

.style-option:hover {
  border-color: var(--theme-color-faded);
  transform: scale(1.05); /* Slight enlargement on hover */
}
