/*.performing-list-container {*/
/*  font-family: Arial, sans-serif;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  padding: 20px;*/
/*  background-color: #ffffff;*/
/*  border-radius: 8px;*/
/*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
/*  box-sizing: border-box;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

/*h2 {*/
/*  text-align: center;*/
/*  margin-bottom: 20px;*/
/*  color: #4b4b8c;*/
/*}*/

/*.search-container {*/
/*  margin-bottom: 20px;*/
/*}*/

/*.search-input {*/
/*  width: 100%;*/
/*  padding: 12px;*/
/*  font-size: 14px;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 5px;*/
/*  box-sizing: border-box;*/
/*}*/

/*!* Performing Table *!*/
/*.performing-table table {*/
/*  width: 100%;*/
/*  border-collapse: collapse;*/
/*}*/

/*.performing-table th,*/
/*.performing-table td {*/
/*  padding: 10px;*/
/*  border: 1px solid #ddd;*/
/*  text-align: left;*/
/*}*/

/*.performing-table th {*/
/*  background-color: #6b63ff;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*}*/

/*.performing-table tr:nth-child(even) {*/
/*  background-color: #f9f9f9;*/
/*}*/

/*.no-top-performing-message {*/
/*  text-align: center;*/
/*  color: #555;*/
/*  font-style: italic;*/
/*}*/

/*!* Buttons *!*/
/*.add-performing-button {*/
/*  display: inline-block;*/
/*  padding: 12px 20px;*/
/*  font-size: 14px;*/
/*  border: none;*/
/*  border-radius: 8px;*/
/*  cursor: pointer;*/
/*  transition: all 0.3s ease;*/
/*}*/

/*.add-performing-button {*/
/*  background-color: #6b63ff;*/
/*  color: white;*/
/*  margin-top: 15px;*/
/*}*/

/*.add-performing-button:hover {*/
/*  background-color: #584fcd;*/
/*}*/

/*!* Create Performing Form *!*/
/*.create-performing-container {*/
/*  margin-top: 20px;*/
/*  padding: 20px;*/
/*  background-color: #f9f9f9;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 8px;*/
/*  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
/*}*/

/*.create-performing-container h2 {*/
/*  text-align: center;*/
/*  margin-bottom: 20px;*/
/*  color: #4b4b8c;*/
/*}*/

/*.form-group {*/
/*  margin-bottom: 15px;*/
/*}*/

/*label {*/
/*  font-weight: bold;*/
/*  color: #4b4b8c;*/
/*  display: block;*/
/*  margin-bottom: 5px;*/
/*}*/

/*input[type="text"],*/
/*textarea {*/
/*  width: 100%;*/
/*  padding: 12px;*/
/*  font-size: 14px;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 5px;*/
/*  box-sizing: border-box;*/
/*}*/

/*textarea {*/
/*  resize: vertical;*/
/*}*/

/*.button-group {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  gap: 10px;*/
/*}*/

/*button {*/
/*  padding: 12px 20px;*/
/*  border: none;*/
/*  border-radius: 8px;*/
/*  font-size: 14px;*/
/*  cursor: pointer;*/
/*  transition: background-color 0.3s ease;*/
/*}*/

/*button:disabled {*/
/*  background-color: #b3b3cc;*/
/*  cursor: not-allowed;*/
/*}*/
