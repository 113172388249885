.dm-container {
    width: 100%;
    max-width: 500px; /* Limit width for better control */
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dm-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
padding-bottom: 10px;
}

.language-select {
    width: 160px;
    margin-bottom: 0px;;
    border: none;
    color: #7C76F5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.header-divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;

}


.dm-container h1 {
    color: #333;
    font-size: 24px;
}



.dm-goal textarea,
.dm-instructions textarea {
    width: 100%;
    padding: 10px;
    color:  #6D7C8D;
    resize: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 20px;  
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #7C76F5;
    background: #FFF;
    color:  #6D7C8D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    font-family: Arial, Helvetica, sans-serif
}

.dm-goal, .dm-instructions, .template-selection {
    margin-bottom: 20px; /* Adjust spacing between form elements */
}

.template-selection{
    margin-top: 35px;
}

.generate-button {
    align-self: center; /* Center the button if needed */
    padding: 10px 20px; /* Make button larger */
    background-color: #6200EE; /* Example button color */
    color: white; /* Text color */
    border: none; /* Remove border */
    cursor: pointer; /* Cursor as pointer */
    border-radius: 4px; /* Rounded corners for the button */
}

.generate-button:hover {
    background-color: #4a54e1; /* Darker shade for hover state */
}

.dm-content-sidebar{
    flex: 1; /* This makes the middle content grow and take available space */
    overflow: auto; /* In case the content is too long */
}
#template{
height: 36px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid #7C76F5;
background: #FFF;
color:  #6D7C8D;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}

.template-selection label {
    color: black;
    margin-bottom: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}


.template-selection p {
    color: #6D7C8D;
margin-top: 0px;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
letter-spacing: -0.5px;
}

.language-select:focus {
    border-color: white; /* Focus color when the select is clicked */
    box-shadow: 0 0 0; /* Optional: Adds glow effect on focus */
}

/* Style for options - limited control, more about color and background */
.language-select option {
    background-color: white; /* Background of options */

}