/* Spinner container styling */
.loading-spinner {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50vh;
}

/* Logo and Ring Container */
.logo-container {
  display: flex;
  position: relative;
  height: 100px;
  width: 100px;
}

/* Loading Logo Styling */
.loading-logo {
  width: 60%;
  height: 60%;
  margin: 20%;
  margin-left: 23%;
  position: absolute;
}

/* Rotating Ring Styling */
.loading-ring {
  width: 100%;
  height: 100%;
  border-top: 6px solid var(--theme-color-dark); /* Purple color for the ring */
  border-radius: 50%;
  animation: spin 1.5s linear infinite; /* Smooth spinning animation */
}

/* Dots Animation Styling */
.loading-dots {
  display: flex;
  gap: 10px; /* Space between dots */
}

.dot {
  margin: 0 5px;
  width: 10px;
  height: 10px;
  background-color: var(--theme-color-dark); /* Purple dot color */
  border-radius: 50%; /* Circular dots */
  animation: dot-flash 1.5s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.loading-spinner p {
  font-weight: bold;
  color: var(--theme-color-dark);
}

/* Dot Flash Animation */
@keyframes dot-flash {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Spinning Animation for Ring */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
