/*.knowledge-list-container {*/
/*  font-family: Arial, sans-serif;*/
/*  width: 100%; !* Full width *!*/
/*  height: 100%; !* Full height *!*/
/*  padding: 20px; !* Internal padding *!*/
/*  background-color: #ffffff;*/
/*  border-radius: 8px;*/
/*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
/*  box-sizing: border-box;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: stretch; !* Stretch children inside container *!*/
/*}*/

/*.search-container {*/
/*  margin-bottom: 20px;*/
/*}*/

/*.search-input {*/
/*  width: 100%;*/
/*  padding: 12px;*/
/*  font-size: 14px;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 5px;*/
/*  box-sizing: border-box;*/
/*}*/

/*.knowledge-table {*/
/*  flex: 1;*/
/*  overflow: auto; !* Handle overflow if table content is too large *!*/
/*  margin-bottom: 20px;*/
/*}*/

/*.knowledge-table table {*/
/*  width: 100%;*/
/*  border-collapse: collapse;*/
/*}*/

/*.knowledge-table th,*/
/*.knowledge-table td {*/
/*  padding: 10px;*/
/*  border: 1px solid #ddd;*/
/*  text-align: left;*/
/*}*/

/*.knowledge-table th {*/
/*  background-color: #6b63ff;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*}*/

/*.knowledge-table tr:nth-child(even) {*/
/*  background-color: #f9f9f9;*/
/*}*/

/*.no-brand-knowledge-message {*/
/*  text-align: center;*/
/*  color: #555;*/
/*  font-style: italic;*/
/*}*/

/*!* Buttons *!*/
/*.add-knowledge-button {*/
/*  display: inline-block;*/
/*  padding: 12px 20px;*/
/*  font-size: 14px;*/
/*  border: none;*/
/*  border-radius: 8px;*/
/*  transition: all 0.3s ease;*/
/*}*/

/*.add-knowledge-button {*/
/*  background-color: #6b63ff;*/
/*  margin-top: 15px;*/
/*  width: 100%; !* Full width button *!*/
/*}*/

/*.add-knowledge-button:hover {*/
/*  background-color: #584fcd;*/
/*}*/

/*#generate-knowledge-button,*/
/*#save-knowledge-button {*/
/*  display: inline-block;*/
/*  padding: 10px 20px;*/
/*  border: none;*/
/*  border-radius: 5px;*/
/*  font-size: 14px;*/
/*  margin: 5px;*/
/*}*/

/*!* Wrapper for CreateKnowledge *!*/
/*.create-knowledge-wrapper {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  padding: 20px;*/
/*  background-color: #ffffff;*/
/*  border-radius: 8px;*/
/*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
/*  margin-top: 20px;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/
