.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Full height within the parent container */
  width: 100%; /* Take up the remaining space */
  max-width: calc(100% - 300px); /* Subtract the sidebar's width */
  margin-left: 300px; /* Align with the sidebar */
  background-color: white; /* Set background */
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  padding: 20px; /* Padding for inner content */
  overflow: hidden; /* Prevent content overflow */
}

/* Chat messages */
.chat-messages {
  flex-grow: 1; /* Take up available vertical space */
  overflow-y: auto; /* Scrollable for overflowing messages */
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid var(--lighter-grey);
  border-radius: 5px;
  word-wrap: break-word;
}

/* Ensure all child wrappers inside .chat-container take full width */
.chat-container > div {
  width: 100%; /* Force each child to span the full width of the container */
}

/* Chat header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--lighter-grey);
  padding-bottom: 10px;
  margin-bottom: 15px; /* Add spacing below the header */
}

.chat-header h3 {
  font-size: 18px; /* Slightly larger font size for the title */
  color: var(--theme-color-dark);
  margin: 0;
}

.chat-header button {
  border: none;
  font-size: 14px;
  padding: 5px 15px; /* Add more padding for better clickability */
  border-radius: 5px;
}

/* Chat metric selection */
.chat-metric-selection {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.chat-metric-selection select {
  flex-grow: 1;
  padding: 8px 10px;
  border: 1px solid var(--lighter-grey);
  background-color: white;
}

.chat-metric-selection button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

/* Chat input area */
.chat-input {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.chat-input textarea {
  flex-grow: 1;
  border: 1px solid var(--lighter-grey);
  resize: none; /* Prevent resizing */
  min-height: 80px; /* Adjust height for typing */
}

.chat-input button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

/* Chat message box */
.chat-message-box {
  white-space: pre-wrap; /* Ensure \n is respected */
  word-wrap: break-word; /* Prevent long words from breaking layout */
  overflow-wrap: anywhere; /* Handle long unbreakable strings */
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  color: var(--darker-grey);
  background-color: #f9f9f9;
}
