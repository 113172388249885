/*.product-list-container {*/
/*  font-family: Arial, sans-serif;*/
/*  width: 100%; !* Full width *!*/
/*  height: 100%; !* Full height *!*/
/*  padding: 20px; !* Internal padding *!*/
/*  background-color: #ffffff;*/
/*  border-radius: 8px;*/
/*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
/*  box-sizing: border-box;*/
/*  display: flex;*/
/*  flex-direction: column; !* Ensure proper stacking *!*/
/*}*/

/*!* Search Input Styling *!*/
/*.search-container {*/
/*  margin-bottom: 20px;*/
/*}*/

/*.search-input {*/
/*  padding: 12px;*/
/*  border: 1px solid #ddd;*/
/*}*/

/*!* Table Styling *!*/
/*.product-table {*/
/*  flex: 1; !* Allow the table to fill available space *!*/
/*  overflow: auto; !* Enable scrolling if content overflows *!*/
/*  margin-bottom: 20px; !* Add spacing below *!*/
/*}*/

/*.product-table table {*/
/*  width: 100%;*/
/*  border-collapse: collapse;*/
/*}*/

/*.product-table th,*/
/*.product-table td {*/
/*  padding: 10px;*/
/*  border: 1px solid #ddd;*/
/*  text-align: left;*/
/*}*/

/*.product-table th {*/
/*  background-color: #6b63ff;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*}*/

/*.product-table tr:nth-child(even) {*/
/*  background-color: #f9f9f9;*/
/*}*/

/*.product-table a {*/
/*  color: #007bff;*/
/*  text-decoration: none;*/
/*}*/

/*.product-table a:hover {*/
/*  text-decoration: underline;*/
/*}*/

/*.no-products-message {*/
/*  text-align: center;*/
/*  color: #555;*/
/*  font-style: italic;*/
/*}*/

/*!* Button Styling *!*/
/*.add-product-button {*/
/*  display: inline-block;*/
/*  padding: 12px 20px;*/
/*  font-size: 14px;*/
/*  border: none;*/
/*  border-radius: 8px;*/
/*  transition: all 0.3s ease;*/
/*}*/

/*!* Add Product Button *!*/
/*.add-product-button {*/
/*  background-color: #6b63ff;*/
/*  margin-top: 15px;*/
/*}*/

/*.add-product-button:hover {*/
/*  background-color: #584fcd;*/
/*}*/

/*!* Input and Label Styling *!*/
/*label {*/
/*  font-weight: bold;*/
/*  color: #4b4b8c;*/
/*  margin-bottom: 8px; !* Add spacing below label *!*/
/*  display: block; !* Ensure label is above input *!*/
/*}*/

/*input[type="text"],*/
/*textarea {*/
/*  padding: 12px;*/
/*  border: 1px solid #ddd;*/
/*}*/
