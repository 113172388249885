/* Ensure brand-voice-form occupies full width */
.brand-voice-form {
  width: 100% !important; /* Full width of parent container */
  max-width: 100% !important; /* Prevent restrictions */
  display: flex !important;
  flex-direction: column !important; /* Stack form blocks vertically */
  gap: 20px !important; /* Add space between form blocks */
  padding: 20px !important; /* Internal padding for spacing */
  box-sizing: border-box !important; /* Include padding and border in width */
  background-color: inherit;
}

/* Ensure form blocks occupy full width */
.form-block {
  display: grid;
  gap: 15px;
  width: 100% !important; /* Full width */
  max-width: 100% !important; /* Prevent restrictions */
  border: 1px solid var(--light-grey) !important; /* Subtle border for separation */
  border-radius: 8px !important; /* Rounded corners */
  padding: 20px !important; /* Internal spacing */
  background-color: white !important; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important; /* Optional shadow for aesthetics */
  box-sizing: border-box !important; /* Ensure padding and border are included in width */
}

/* Ensure rows maintain alignment */
.name-row {
  display: flex !important;
  flex-wrap: wrap !important; /* Ensure items wrap on smaller screens */
  gap: 20px !important; /* Space between fields */
  width: 100% !important; /* Full width */
}

/* Ensure individual form groups are responsive */
.form-group {
  flex: 1 !important; /* Allow equal width for form elements */
  min-width: 200px !important; /* Set a minimum width for smaller screens */
}

/* Ensure save button stretches fully */
.persona-save-button {
  width: 100% !important; /* Full width button */
  padding: 12px !important; /* Internal padding */
  border: none !important; /* Remove border */
  border-radius: 5px !important; /* Rounded corners */
}

.tone-container {
  display: grid;
  gap: 10px;
}

.options-block {
  display: grid;
  gap: 15px;
}

.add-comment-button {
  background-color: #28a745;
  border: none;
  padding: 8px 12px;
  margin-top: 10px;
  border-radius: 5px;
}

.add-comment-button:hover {
  background-color: #218838;
}

.remove-button-comment {
  background-color: #dc3545;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  width: auto; /* Prevent full width */
  height: 50px; /* Prevent large height */
  display: inline-block; /* Keep the button small */
  line-height: normal;
  text-align: center;
}

.remove-button-comment:hover {
  background-color: #c82333;
}
