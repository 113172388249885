.profile-settings-container {
  margin: 0 auto;
  background-color: #f8f9fc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  height: 100%; /* Full height within the parent container */
  width: 100%; /* Take up the remaining space */
}

.profile-settings-header {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--darker-grey);
}

.profile-settings-section {
  margin-bottom: 20px;
}

.block-container {
  margin-bottom: 30px; /* Add space between Profile Edit and Change Password blocks */
  padding: 20px; /* Add padding inside each block */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.profile-field label {
  font-size: 1rem;
  margin-bottom: 8px;
  color: var(--dark-grey);
}

.profile-field input,
.profile-field select {
  font-size: 1rem;
  padding: 8px 10px;
  border: 1px solid var(--lighter-grey);
  outline: none;
  transition: border-color 0.3s ease;
}

.profile-field input:focus,
.profile-field select:focus {
  border-color: #6c63ff;
}

/* Flex container for fields on the same row */
.flex-container {
  display: flex;
  gap: 20px; /* Add space between fields */
  justify-content: space-between;
}

.flex-container .profile-field {
  flex: 1; /* Ensure both fields share available space equally */
}

.save-button {
  display: block;
  width: 50%; /* Make buttons smaller */
  margin: 0 auto;
  padding: 8px; /* Smaller padding */
  font-size: 0.9rem; /* Reduce font size */
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.save-message {
  margin-top: 10px;
  text-align: center;
  font-size: 1rem;
  color: #4caf50;
}
