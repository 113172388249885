/* .dm-page-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding-right:20px;

  } */
  
  .dm-header-section {
    margin-bottom: 20px;
    background-color: white;
  }
/*   
  .dm-content {
    display: flex;
    flex-grow: 1;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 93%;
    max-width: 100%;
  } */
  /* .grid-section{
    flex: 0 1 40%; /* Smaller area for progress and parameters */
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    background: #EAEAF4;
    padding: 0px;
    border-radius: 8px;
    grid-gap: 20px;
    max-width: 100%; */
  /* } */
  /* .left-section {
    flex: 3; /* Larger area for main content */
  /* }  */
  .chart-container{
    display: flex;
    align-items: center;
  }
  /* .right-section {
    flex: 0.5; /* Smaller area for progress and parameters */
    /* display: grid; */
    /* grid-template-rows: 0.5fr 1fr;
    background: #EAEAF4;
    padding: 0px;
    border-radius: 8px;
    grid-gap: 20px; */
  /* }  */

  .dm-page-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;  /* Full width to ensure it fills the parent */
    height: 100vh;  /* Full viewport height */
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .dm-content {
    display: flex;
    flex-grow: 1;  /* Ensures it fills available vertical space */
    margin-top: 20px;
    margin-bottom: 20px;
    height: 93%;
  }
  
  .grid-section {
    display: flex;
    width: 100%;
    background: #EAEAF4;
    padding: 0;
    border-radius: 8px;
  }
  
  .left-section {
    flex: 6;  /* Allocates 3x more space to left than right */
    padding: 10px;  /* Provides some internal spacing */
    padding-left: 0px;
  }
  
  .right-section {
    flex: 4;  /* Smaller space allocation */
    display: flex;
    flex-direction: column;
    padding: 10px;  /* Aligns padding with left-section */
    gap: 10px;  /* Space between internal components */
    margin-top: 20px;
    padding-right: 0px;
  }
  
  .progress-score-container {
    flex: 1;  /* Allows this to expand within right-section */
    display: flex;
    flex-direction: column;
    align-items: center;  /* Centers content horizontally */
    justify-content: space-around;  /* Distributes spacing vertically */
  }
  
  .score-buttons {
    display: flex;
   
    justify-content: space-between;  /* Evenly spaces buttons */
    margin-top: 10px;  /* Additional space from the above content */
  }
  .dm-form {
    display: flex;
    align-items: center; /* Align items in a row at their center */
    background-color: #fff; /* White background for the input and button section */
    height: 44px;
    margin-bottom: 0px;;
    border-radius: 8px; /* Rounded corners */
    padding: 8px; /* Padding around the content */
  }
  
  .dm-form input[type="text"] {
    width: 100%;           /* Makes the input take up the full width of its container */
    padding: 8px 12px;     /* Adds padding inside the input for better text visibility */
    margin: 10px 0;        /* Adds margin around the input for spacing */
    border-radius: 4px;    /* Rounds the corners of the input field */
    font-size: 16px;    
    border: none;   
 
}
  
  .dm-form button.save-dm {
    display: flex;
    width: 118px;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    background:  #7C76F5;
    border: none; 
    color:  #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

  
  .dm-form button.save-dm:hover {
    background-color: #5c0de6; /* Slightly darker purple on hover */
  }

  .dm-template-section{
    background-color: white;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 10px;
    width: 100%;
  }
  .dm-template-section-in{
    display: flex;
    align-items: center;  /* Aligns items vertically in the center */
    text-align: left;
    gap: 10px;
  }

  .dm-template-section h1{
    width: 316px;
    color:  #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
  }
  .dm-template-section p{
    color:  #6D7C8D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
    padding: 0px;
    margin-top: 0;
    text-align: left;
  }
  .dm-template-preview, .edit-request-section {
    background: white;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This will push the name and the icons to opposite ends */
    width: 100%; /* Ensure it takes full width of its container */
    margin-bottom: 10px;
  }
  
  .profile-info {
    display: flex;
    align-items: center;
  }
  
  .icon-copy, .icon-edit {
    width: 18px;
    height: 18px;
    margin-left: 10px; /* Maintain spacing between icons */
  }
  .vector-icon {
    height: 1em; /* Aligns the height with the font size of the text */
    width: auto; /* Keeps the aspect ratio of the icon */
    vertical-align: middle; /* Aligns the icon vertically with the text */
    margin-left: 5px; /* Optional: adds some space before the icon */
    width: 20px;
    height: 18px;
    flex-shrink: 0;
    aspect-ratio: 13/12;
    margin-bottom: 10px;
}
  
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .icon-edit, .icon-delete {
    width: 18px; /* Adjust size as needed */
    height: 18px; /* Adjust size as needed */
    margin-left: 10px; /* Space between name and icon */
  }
  .dm-body {
    margin-bottom: 20px;
  }
  
  .edit-request-section {
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    border: none;  /* Optional, adds a slight border to the section if needed */
    color: #9EA1A1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom:20px;
}
  
  .edit-request-section .edit-request-input:focus {
    outline: none !important; /* Force removal of the outline */
    border: none !important; /* Force removal of any borders */
    box-shadow: none !important;
}

  .edit-request-input {
    flex-grow: 1;
    padding: 12px 48px 12px 12px; /* Right padding is larger to accommodate the button */
    border: none;  /* No border for the input field */
    outline: none;  /* Remove focus outline */
    background-color: transparent;  /* Ensure background matches section */
    font-size: 16px;
    box-shadow: none;
    width: 100%; /* Ensure it fills the container */
  }
  
  .edit-request-btn {
    position: relative;
    display: flex;
    align-items: center;  /* Ensures vertical center alignment of all children */
    justify-content: center;  /* Centers horizontally */
    background: linear-gradient(90deg, #7C76F5 0%, #416BFF 100%);
    border: none;
    border-radius: 16px;
    color: white;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
    color:  #FFF;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    width: 120px;
    height: 45px;
    flex-shrink: 0;
    align-items: center;
 
  }
  .button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px; /* Adjust padding to fit content */
}

.top-content {
    display: flex;
    align-items: center;
    font-size: 10px;
    gap: 4px; /* Space between the SVG icon and text */
}
  
  /* requestIconEditStyles.css */
.request-icon-edit {
    width: 17.061px;
    height: 22px;
    flex-shrink: 0;
    padding-bottom: 5px;
}

.coming-soon-text {
  font-size: 9px; /* Smaller font size for subtext */
 
}
  
  .edit-request-btn:hover {
    background-color: #5c0de6;
  }
  .dynamic-parameters{
    margin-bottom: 20px;
  }
  
  .dynamic-parameters, .progress-score-container {
    /* grid-column: span 2; */
    padding: 10px;
    background: white;

    border-radius: 8px;
  }
/* 
  .progress-score-container{
    display: flex;
  flex-direction: column;
  align-items: center; /* Centers children horizontally in the flex container */
  /* justify-content: center; /* Centers children vertically if there's extra space */
  /* padding: 20px; /* Adds padding around the inside of the container */
  /* gap: 20px; 
  margin-top: 20px; */ 
  /* } */ 
  .chart-icon {
    width: 28.879px;
    height: 28.879px;
    flex-shrink: 0;
    margin-bottom: 10px; /* Space between the icon and the scale value */
  }
  
  .scale-value {
    color: #2F2F2F;
text-align: center;
font-family: Arial, Helvetica, sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 12.571px; /* 62.857% */
text-transform: capitalize;
    margin-bottom: 20px; /* Space below the scale value, before the next section */
  }
  
  /* .score-buttons {
    display: flex;
    justify-content: space-between; /* Ensures buttons are spaced evenly */
    /* padding: 10px; /* Adds padding inside the container for breathing room */
    /* gap: 10px; Space between each button */ */
  /* }  */
  
 
  .header-divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;

}
  .dynamic-parameters h2 {
    width: 255px;
    height: 30px;
    flex-shrink: 0;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    text-align: left;
   
  }
  .dynamic-parameters p {
    color: #393540;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-align: left;
  }
  
  .dynamic-parameters ul {
    list-style: none;
    padding: 0;
  }
  
  .dynamic-parameters li {
    padding: 8px;
    margin-bottom: 5px;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dynamic-parameters li:hover {
    background-color: #d2d3db;
  }
  
  .response-section{
    background: #EAEAF4;
  }

  .dm-body{
    /* width: 498px; */
    height: 313px;
    flex-shrink: 0; 
    color: rgba(0, 0, 0, 0.90);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    text-align: left;
  }

  .parameter {
    color: #7C76F5;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  .parameter:hover {
    background-color: transparent; /* This will ensure no background color change on hover */
    text-decoration: none; /* Removes underline or other text decorations */
    color: inherit; /* Keeps the color consistent on hover */
  }
  
  
  .description {
    color: #6D7C8D;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  
  li {
    margin-bottom: 8px; /* Adds space between list items */
    text-align: left;
}
.dynamic-parameters li:hover {
    background-color: transparent; /* Ensures no background change on hover */
    color:inherit; /* Adjust the color as needed, here it's set to a standard dark grey */
}

.dynamic-parameters li:hover {
    color: #7C76F5;
    cursor: pointer; /* Ensures it's clear it's clickable */
}

  
button.progress-button-boost {
  display: flex;
  width: 100px;
  height: 38px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: -4px;
  flex-shrink: 0;
  background-color: #7C76F5;
  color: white;
  border-radius: 8px;
  border: 1px solid var(--Grey-Border-color, #E9EBED);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-right: 10px;
  }
  button.progress-button-regenerate, button.progress-button-refine {
    display: flex;
    width: 100px;
    height: 38px;
    padding: 6px ;
    justify-content: center;
    align-items: center;
    gap: -4px;
    flex-shrink: 0;
    background-color: white;
    color: grey;
    border-radius: 8px;
    border: 1px solid var(--Grey-Border-color, #E9EBED);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-right: 10px;
    }