.domain-competitor-table-container {
  width: 100%; /* Full width of its parent container */
  max-width: 1200px; /* Limit the maximum width */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px;
  background-color: #f8f9fc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Enable horizontal scrolling for wide tables */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.general-info {
  margin-bottom: 20px;
}

.general-info h3 {
  font-size: 1.4rem;
  margin-bottom: 5px;
}

.general-info p {
  font-size: 1rem;
  margin: 3px 0;
}

.domain-competitor-table {
  width: 100%;
  border-collapse: collapse; /* Combine table borders */
  text-align: left;
  min-width: 1000px; /* Ensure minimum width for readability */
}

.domain-competitor-table th,
.domain-competitor-table td {
  padding: 10px;
  border-bottom: 1px solid var(--lighter-grey);
  white-space: nowrap; /* Prevent text wrapping in table cells */
}

.domain-competitor-table th {
  background-color: #e9ecef;
  font-weight: bold;
  font-size: 0.9rem;
  color: #495057;
  position: sticky; /* Keep header visible when scrolling */
  top: 0;
  z-index: 1; /* Ensure it stays above the rows */
}

.domain-competitor-table td {
  font-size: 0.9rem;
}

.domain-competitor-table td div {
  margin-bottom: 4px;
}

.domain-competitor-table-container::-webkit-scrollbar {
  height: 8px; /* Adjust scrollbar height */
}

.domain-competitor-table-container::-webkit-scrollbar-thumb {
  background-color: var(--light-grey); /* Customize scrollbar thumb color */
  border-radius: 4px; /* Smooth edges */
}

.domain-competitor-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb; /* Darker color on hover */
}

/* Alternating row colors for better readability */
.domain-competitor-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.domain-competitor-table tr:hover {
  background-color: #e9e9e9; /* Highlight row on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .general-info h3 {
    font-size: 1.2rem;
  }

  .general-info p {
    font-size: 0.9rem;
  }

  .domain-competitor-table th,
  .domain-competitor-table td {
    font-size: 0.8rem;
    padding: 8px;
  }

  .domain-competitor-table td div {
    font-size: 0.8rem;
  }
}
