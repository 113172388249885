form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 500px;
  margin: 0;
  padding: 10px;
  background-color: white;
  border-radius: 0; /* Removed rounded corners */
  box-shadow: none; /* Removed shadow */
  position: relative;
}

/* Styling for labels */
form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--darker-grey);
}

/* Section header for collapsible areas */
.section-header {
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: var(--theme-color-dark);
}

.section-header span {
  font-size: 20px;
  margin-right: 10px;
}

.section-header:hover {
  color: var(--theme-color-faded);
}

/* Section content is hidden by default */
.section-content {
  padding-left: 10px;
  margin-bottom: 15px;
}

.button-row {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.button-row button {
    padding: 10px 20px;
    background-color: var(--theme-color-dark);
    border: none;
    border-radius: 5px;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px; /* Space between checkbox and label */
  vertical-align: middle; /* Aligns the checkbox with the label text */
}

/* General Section Styling */
/* Section toggle animation */
.section {
  border: 1px solid var(--lighter-grey);
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: white;
  padding: 10px;
}

/* Heading Styling */
.section h4 {
  margin-bottom: 10px;
}

h4 span {
  font-size: 14px;
  color: var(--darker-grey);
}

/* Item List */
.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

/* Custom Checkbox Styling */
.custom-checkbox {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

/* Search Box Styling */
.search-box-container {
  margin-bottom: 10px;
  padding: 0 10px;
}

/* Search Box */
.search-box {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Styling for the scrollable container */
.scrollable-container {
  padding-top: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--lighter-grey);
  border-radius: 8px;
  background-color: white;
  max-height: 300px; /* Total height for the container */
  overflow-y: auto;
}

/* Search Box Styling */
.search-box-wrapper {
  margin-bottom: 10px;
}

/* Scrollable List */
.scrollable-section {
  max-height: 250px; /* Subtract height of search box */
  overflow-y: auto;
}

.form-header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lighter-grey);
}

.form-header-title {
  flex: 4;
  font-weight: bold;
  font-size: 24px;
}

.form-header-language {
  flex: 5;
  border: none;
  color: var(--theme-color);
}

/* Because language selector has no border, add effect on hover to show interactivity */
.form-header-language:hover {
  box-shadow: 0 0 5px rgba(85, 60, 154, 0.5); /* Optional shadow effect */
}