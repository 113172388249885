/*.target-audience-container {*/
/*  font-family: Arial, sans-serif;*/
/*  width: 100%; !* Full width of the parent container *!*/
/*  height: 100%; !* Full height of the parent container *!*/
/*  margin: 0; !* Remove any default margin *!*/
/*  padding: 20px; !* Maintain internal padding *!*/
/*  background-color: #ffffff; !* Background color *!*/
/*  border-radius: 8px; !* Rounded corners *!*/
/*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); !* Subtle shadow *!*/
/*  box-sizing: border-box; !* Include padding in width/height calculations *!*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  overflow-y: auto; !* Add scroll if content overflows *!*/
/*}*/

/*.response-section {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  height: 100%; !* Full height of the container *!*/
/*  width: 100%; !* Full width of the container *!*/
/*  box-sizing: border-box; !* Include padding and border *!*/
/*  overflow: hidden; !* Prevent content overflow *!*/
/*}*/

/*h2,*/
/*h3 {*/
/*  color: #4b4b8c;*/
/*  margin-bottom: 10px;*/
/*  font-size: 1.5rem; !* Scalable font size *!*/
/*}*/

/*.input-group-row {*/
/*  display: flex;*/
/*  flex-wrap: wrap; !* Allow wrapping for smaller screens *!*/
/*  gap: 15px;*/
/*  margin-bottom: 20px;*/
/*}*/

/*.input-group-row input,*/
/*select {*/
/*  flex: 1; !* Make inputs responsive *!*/
/*  padding: 12px;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 8px;*/
/*}*/

/*.slider-container {*/
/*  margin: 20px auto;*/
/*  text-align: center;*/
/*}*/

/*.custom-slider {*/
/*  margin: 20px auto;*/
/*  width: 100%; !* Full width for responsiveness *!*/
/*  max-width: 600px; !* Limit maximum width *!*/
/*}*/

/*.custom-slider-thumb {*/
/*  height: 20px;*/
/*  width: 20px;*/
/*  background-color: #6b63ff;*/
/*  border-radius: 50%;*/
/*}*/

/*.custom-slider-track {*/
/*  background-color: #6b63ff;*/
/*  height: 5px;*/
/*}*/

/*.slider-labels {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  margin: 0 auto;*/
/*  width: 100%;*/
/*  max-width: 600px; !* Match slider width *!*/
/*  color: #4b4b8c;*/
/*}*/

/*.options-container {*/
/*  margin-bottom: 10px;*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*  gap: 10px;*/
/*  max-width: 100%; !* Ensure content fits within the container *!*/
/*}*/

/*.option-item {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  padding: 8px 12px;*/
/*  background-color: #f8f9fa;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 8px;*/
/*  word-wrap: break-word; !* Ensure long text wraps *!*/
/*  max-width: calc(100% - 40px); !* Prevent overflow *!*/
/*}*/

/*.remove-button {*/
/*  background: none;*/
/*  border: none;*/
/*  color: red;*/
/*}*/

/*.generate-button,*/
/*.save-button {*/
/*  display: inline-block;*/
/*  background-color: #6b63ff;*/
/*  color: #fff;*/
/*  padding: 12px 20px; !* Ensure the same size for both buttons *!*/
/*  border: none;*/
/*  border-radius: 8px;*/
/*  font-size: 14px; !* Consistent font size *!*/
/*  margin: 10px 10px 0 0; !* Add spacing between the buttons *!*/
/*  width: 200px; !* Ensure buttons are the same width *!*/
/*  text-align: center; !* Center the text inside the button *!*/
/*}*/

/*.generate-button:hover,*/
/*.save-button:hover {*/
/*  background-color: #584fcd;*/
/*}*/

/*.add-button {*/
/*  background-color: #007bff;*/
/*  border: none;*/
/*  border-radius: 5px;*/
/*  padding: 8px 12px;*/
/*  font-size: 14px;*/
/*  transition: background-color 0.3s ease, transform 0.2s ease;*/
/*}*/

/*.add-button:hover {*/
/*  background-color: #0056b3;*/
/*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);*/
/*}*/

/*.add-button:active {*/
/*  background-color: #003f7f;*/
/*  transform: scale(0.98);*/
/*}*/

/*textarea {*/
/*  min-height: 100px; !* Set a reasonable height *!*/
/*  padding: 12px;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 8px;*/
/*  resize: vertical; !* Allow vertical resizing only *!*/
/*}*/
