.dashboard-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  color: var(--darker-grey);
  overflow-y: auto;
}

.header {
  background-color: #eef1f7;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
  font-size: 26px;
  color: #1f2e5a;
}

.header p {
  font-size: 16px;
  color: #606c88;
}

.image-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.dashboard-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.documents-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.documents-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.documents-table th,
.documents-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid var(--lighter-grey);
}

.documents-table th {
  background-color: #eef1f7;
  font-size: 14px;
}

.documents-table .document-row:hover {
  background-color: #f8f9fb;
  cursor: pointer;
}

.document-details {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.document-details h3 {
  font-size: 20px;
  color: #1f2e5a;
}

.document-info .detail-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid var(--lighter-grey);
}

.document-info .detail-row strong {
  font-size: 14px;
  color: var(--darker-grey);
}

.document-info .detail-row span {
  font-size: 14px;
  color: var(--dark-grey);
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
}

.close-button:hover {
  background-color: #c9302c;
}

.search-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 15px;
}

.spinner {
  text-align: center;
  font-size: 16px;
  color: #007bff;
  padding: 20px;
}

/* Autocomplete suggestions container */
.suggestions {
  position: absolute;
  background-color: white;
  border: 1px solid var(--lighter-grey);
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin-top: 5px;
  list-style: none;
}

/* Each suggestion item */
.suggestion-item {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  color: var(--darker-grey);
  transition: background-color 0.2s ease, color 0.2s ease;
}

/* Hover effect for suggestion items */
.suggestion-item:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

/* Active or selected suggestion */
.suggestion-item:active {
  background-color: #e0e0e0;
  color: #0056b3;
}

.filters {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.filters select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  background-color: #f9f9f9;
  color: var(--darker-grey);
  cursor: pointer;
  appearance: none; /* Removes default arrow styling */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%23666" d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  transition: all 0.3s ease;
}

.filters select:focus {
  border-color: var(--theme-color-dark); /* Highlighted border on focus */
  box-shadow: 0 0 5px rgba(85, 60, 154, 0.5); /* Glow effect */
  outline: none;
}

.filters select:hover {
  background-color: #f1f1f1; /* Slightly lighter background on hover */
}

.filters .autocomplete {
  position: relative;
}

.filters .autocomplete input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  width: 200px;
}

.filters .suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filters .suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.filters .suggestion-item:hover {
  background-color: #f9f9f9;
}
