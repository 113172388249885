/* Full-page layout */
.login-page {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* Left panel */
.login-left {
  flex: 1;
  background: linear-gradient(135deg, #6b63ff, #9288e0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 40px;
  text-align: center;
}

.login-left-content {
  max-width: 400px;
}

.login-left-image {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.login-left h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.login-left p {
  font-size: 16px;
  line-height: 1.5;
}

/* Right panel */
.login-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EAEAF4;
}

.login-form {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
  flex-grow: unset; /* Ugly workaround because of the CreateAd form style. Need to create a class for that form */
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.login-header-icon {
  width: 50px;
  margin-bottom: 10px;
}

.login-header h2 {
  font-size: 22px;
  color: var(--theme-color-dark);
}

.login-header p {
  font-size: 14px;
  color: #888;
}

/* Input fields */
.login-input-group {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 15px;
}

.login-input {
  border: 1px solid var(--lighter-grey);
  background-color: #f9f9f9;
}

.toggle-password-button {
  position: absolute;
  right: 10px;
  margin-top: 3px; /* small offset correction to center image */
  background: transparent;
  border: none;
}

.toggle-password-button svg path {
  fill: var(--light-grey);
  transition: fill 0.3s ease; /* Smooth color transition */
}

.toggle-password-button:hover {
  background: none;
}

.toggle-password-button:focus{
  outline: 2px solid var(--theme-color-dark);
  outline-offset: 2px;
  border-radius: 4px;
}

.toggle-password-button:hover svg path,
.toggle-password-button:focus svg path{
  fill: var(--darker-grey); /* Change icon color on hover */
}

/* Buttons */
.login-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.google-login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  color: var(--dark-grey);
  background-color: white;
  border: 1px solid var(--lighter-grey);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.google-login-button:hover {
  background-color: #f9f9f9;
}

.google-logo {
  width: 20px;
  margin-right: 10px;
}

/* Divider */
.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: #333;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1; /* Make the lines take equal space */
  height: 1px;
  background-color: var(--lighter-grey);
  margin: 0 10px; /* Space between the text and lines */
}


/* Signup link */
.signup-link {
  text-align: center;
  margin-top: 20px;
}

.signup-link a {
  color: var(--theme-color-dark);
  text-decoration: none;
  font-weight: bold;
}

.signup-link a:hover {
  text-decoration: underline;
}

/* Forgot password link */
.forgot-password-link {
  display: block;
  text-align: right;
  color: var(--theme-color-dark);
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 10px;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.contact-links {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9em;
  color: var(--dark-grey);
}

.contact-links a {
  color: #007bff;
  text-decoration: none;
}

.contact-links a:hover {
  text-decoration: underline;
}
