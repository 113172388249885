.header {
  background-color: #eef1f7;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
  font-size: 26px;
  color: #1f2e5a;
}

.header p {
  font-size: 16px;
  color: #606c88;
}

.image-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.documents-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.documents-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.documents-table th,
.documents-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid var(--lighter-grey);
}

.documents-table th {
  background-color: #eef1f7;
  font-size: 14px;
}

.documents-table .document-row:hover {
  background-color: #f8f9fb;
  cursor: pointer;
}

.document-details {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.document-details h3 {
  font-size: 20px;
  color: #1f2e5a;
}

.document-info .detail-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid var(--lighter-grey);
}

.document-info .detail-row strong {
  font-size: 14px;
  color: var(--darker-grey);
}

.document-info .detail-row span {
  font-size: 14px;
  color: var(--dark-grey);
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
}

.close-button:hover {
  background-color: #c9302c;
}

.spinner {
  text-align: center;
  font-size: 16px;
  color: #007bff;
  padding: 20px;
}

.favorite-text {
  cursor: pointer;
  font-weight: 500;
  color: #333;
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s ease;
}

.favorite-text:hover {
  color: var(--theme-color-dark); /* Subtle highlight effect */
}

.view-full {
  color: var(--theme-color-dark);
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
}

.view-full:hover {
  text-decoration: underline;
}

/* Button for selecting a post */
.favorite-select-button {
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
}

.delete-button {
  background-color: #ff4d4d;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
}

.delete-button:hover {
  background-color: #cc0000;
}

table .loading-spinner {
  left: 50%;
}
