.competitors-keywords-table-container {
  width: 100%; /* Full width of its parent container */
  max-width: 900px; /* Limit maximum width */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Enable horizontal scrolling for overflow content */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.competitors-keywords-info {
  margin-bottom: 15px;
  font-size: 16px;
}

.competitors-keywords-info p {
  margin: 5px 0;
}

.competitors-keywords-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  min-width: 800px; /* Ensure minimum width for proper column display */
}

.competitors-keywords-table th,
.competitors-keywords-table td {
  padding: 12px;
  border-bottom: 1px solid var(--lighter-grey);
  white-space: nowrap; /* Prevent text wrapping */
}

.competitors-keywords-table th {
  background-color: #f0f0f0;
  font-weight: bold;
  font-size: 14px; /* Slightly smaller text for better fit */
  text-align: left;
  position: sticky; /* Keep the headers visible during scrolling */
  top: 0;
  z-index: 2; /* Ensure it stays on top */
}

.competitors-keywords-table td {
  font-size: 14px; /* Adjust font size for better readability */
  color: #333;
  white-space: nowrap; /* Prevent text wrapping */
  overflow-wrap: break-word; /* Allow long words to break */
}

.competitors-keywords-table td a {
  color: var(--theme-color-dark);
  text-decoration: none;
  word-wrap: break-word; /* Break long links if necessary */
}

.competitors-keywords-table td a:hover {
  text-decoration: underline;
}

/* Add horizontal scrolling for smaller screens */
.competitors-keywords-table-container::-webkit-scrollbar {
  height: 8px; /* Adjust scrollbar height */
}

.competitors-keywords-table-container::-webkit-scrollbar-thumb {
  background-color: var(--light-grey); /* Customize scrollbar thumb color */
  border-radius: 4px;
}

.competitors-keywords-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

/* Alternating row colors for readability */
.competitors-keywords-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.competitors-keywords-table tr:hover {
  background-color: #f0f0f0; /* Highlight row on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .competitors-keywords-info {
    font-size: 14px;
  }

  .competitors-keywords-table th,
  .competitors-keywords-table td {
    font-size: 12px; /* Reduce font size for smaller screens */
    padding: 10px;
  }

  .competitors-keywords-table td a {
    font-size: 12px;
  }
}
