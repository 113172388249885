/* Sidebar styles */
.sidebar {
  width: 296px;
  background-color: white;
  height: 100vh;
  position: relative;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden; /* Prevent overflow in collapsed state */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sidebar-content {
  flex-grow: 1; /* Allow middle section to take available space */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  padding-right: 5px; /* Avoid scrollbar overlap */
}

/* Scrollbar styles */
.sidebar-content::-webkit-scrollbar {
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.sidebar.collapsed {
  width: 80px; /* Reduced width when collapsed */
}

/* Logo container */
.sidebar-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  gap: 10px;
}

.logo {
  width: 50px;
  height: 50px;
  display: block;
  cursor: pointer; /* Makes it clear the logo is clickable */
}

/* Product name */
.product-name {
  font-size: 26px;
  font-weight: bold;
  margin-top: 10px;
  color: var(--darker-grey);
  white-space: nowrap;
}

.sidebar.collapsed .product-name {
  display: none;
}

/* Toggle Icon */
.toggle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Add spacing below the logo */
  cursor: pointer;
}

.toggle-icon .icon {
  width: 30px;
  height: 30px;
}

/* Smaller close icon for expanded state */
.toggle-icon-expanded {
  position: absolute; /* Position the close icon above the logo */
  top: 10px; /* Adjust as needed */
  right: 10px; /* Align to the right of the sidebar */
  cursor: pointer;
  z-index: 2; /* Ensure it is above other elements */
}

.icon-small {
  width: 20px; /* Smaller size for the close icon */
  height: 20px;
}

/* Sidebar title with icon */
.sidebar-title-with-icon {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebar-title-with-icon:hover {
  background-color: #f0f0f5; /* Light hover effect */
  transform: translateX(5px);
}

.sidebar-title {
  font-size: 16px;
  color: #6D7C8D;
  flex-grow: 1;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}

.sidebar-title-with-icon.open-dropdown .sidebar-title {
  color: white; /* Ensure the title text is white */
}

/* Arrow indicators */
.arrow-up::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--dark-grey); /* THIS completes the arrow */
}

.arrow-down::after {
  border-top: 5px solid var(--theme-color);
}

.sidebar-title-with-icon.open-dropdown .arrow-up::after {
  border-bottom: 5px solid white;
}

/* Dropdown Menu */
.dropdown-menu {
  list-style: none;
  margin-top: 10px;
  padding: 0;
  width: 100%;
}

.dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 10px 55px;
  cursor: pointer;
  color: #6D7C8D;
  margin-bottom: 5px;
  box-shadow: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.dropdown-menu li.active,
.sidebar-title-with-icon.active {
  border-left: 3px solid var(--theme-color);
}

.sidebar-title-with-icon.open-dropdown {
  background-color: var(--theme-color); /* Purple background */
}

.dropdown-menu li:hover {
  background-color: #f0f0f5; /* Light hover effect */
  transform: translateX(5px);
}

.dropdown-menu li.active span,
.sidebar-title-with-icon.active span {
  color: var(--theme-color);
  font-weight: bold;
}

/* Dropdown icon */
.sidebar-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-color: transparent; /* Ensure transparent background */
  border: none; /* Remove any border if present */
}

.active .sidebar-icon rect {
  stroke: var(--theme-color);
}

.active .sidebar-icon path,
.open-dropdown .sidebar-icon path {
  fill: var(--theme-color);
}

.active .sidebar-icon.stroke-icon path {
  fill: none;
  stroke: var(--theme-color);
}

.open-dropdown .sidebar-icon.stroke-icon path  {
  stroke: white;
}

.open-dropdown .sidebar-icon.inverted-icon path  {
  fill: white;
}
