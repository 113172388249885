.keywords-suggestions-table-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.keywords-suggestions-overview {
  margin-bottom: 20px;
  font-size: 1rem;
  color: var(--darker-grey);
}

.keywords-suggestions-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.keywords-suggestions-table th,
.keywords-suggestions-table td {
  padding: 12px;
  border-bottom: 1px solid var(--lighter-grey);
}

.keywords-suggestions-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.keywords-suggestions-table td {
  color: var(--theme-color-dark);
}
