.search-console-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-console-title {
  text-align: center;
  font-size: 24px;
  color: #553c9a;
  margin-bottom: 20px;
}

.search-console-error {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.search-console-loading {
  color: #333;
  text-align: center;
}

.search-console-form {
  display: flex;
  flex-direction: column;
}

.search-console-label {
  font-size: 16px;
  color: #333;
  margin-top: 10px;
}

.search-console-select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.search-console-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.search-console-date-input {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.search-console-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}

.search-console-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--theme-color-dark);
  border-radius: 50%;
  margin: 20px auto;
  animation: spin 1s linear infinite;
}
