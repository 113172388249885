/* Container for Split Layout */
.login-info-container {
  display: flex;
  height: 100vh;
  background-color: white;
}

/* Left Section - Branding and Illustration */
.login-image-container {
  flex: 1;
  background: linear-gradient(90deg, #6b63ff 0%, #9f88ff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 40px;
  text-align: center;
}

.login-image-container img {
  max-width: 80%;
  height: auto;
  margin-bottom: 20px;
}

.brand-text {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.brand-description {
  font-size: 16px;
  line-height: 1.5;
}

/* Right Section - Form */
.login-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical centering */
  align-items: center; /* Horizontal centering */
  padding: 40px;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
}

/* Header Styles for Form */
.login-form-container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #4b4b8c;
  margin-bottom: 20px;
  text-align: center;
}

.login-form-container p {
  color: #6b63ff;
  margin-bottom: 20px;
  text-align: center;
}

/* Separator */
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  margin: 20px 0;
  font-size: 12px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  height: 1px;
  background: var(--lighter-grey);
  margin: 0 10px;
}

/* Buttons */
.next-button {
  width: auto; /* Allow button to size based on content */
  max-width: 200px; /* Restrict to a maximum width */
  padding: 12px 16px; /* Add padding for button text */
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.next-button:hover {
  transform: translateY(-2px); /* Add a hover lift effect */
}

/* Google Button Styling */
.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  width: 100%;
  max-width: 432px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--lighter-grey);
  border-radius: 8px;
  background-color: white;
  color: var(--dark-grey);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.google-button:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px); /* Add hover effect */
}

/* Debugging (Temporary) */
.debug-box {
  border: 1px dashed red;
}

.header-section h2 {
  font-size: 28px;
  font-weight: 700;
  color: #4b4b8c;
  margin-bottom: 8px;
}

.header-section p {
  font-size: 16px;
  color: #6b63ff;
  margin-bottom: 24px;
}

/* Input Group Styles */
.input-group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.signup-input {
  height: 44px;
  border: 1px solid var(--border-grey);
  border-radius: 8px;
}

.signup-input::placeholder {
  color: #999;
  font-size: 14px;
}

/* Button Group */
.button-group {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  gap: 16px; /* Add spacing between buttons */
  width: 100%; /* Full width of the button group */
  max-width: 400px; /* Optional: Limit the maximum width of the buttons */
}

.previous-button,
.next-button {
  flex: 1;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.previous-button:hover,
.next-button:hover {
  transform: translateY(-2px);
}

/* Center Form */
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 100vh;
  background-color: #f9f9fc;
}

/* Signup Header Styling */
.signup-header {
  width: 100%; /* Set width based on Figma */
  height: 301px; /* Set height based on Figma */
  flex-shrink: 0; /* Prevent shrinking */
  border-radius: 16px 20px 0px 0px; /* Rounded corners as specified */
  background: rgba(124, 118, 245, 0.5); /* Semi-transparent purple background */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center content */
  justify-content: space-between; /* Space between the text and image */
  padding: 24px 32px; /* Add padding for spacing */
  box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
  overflow: hidden; /* Prevent content overflow */
}

/* Header Text Section */
.signup-header-text {
  flex: 1; /* Allow text to take up remaining space */
  display: flex;
  flex-direction: column; /* Stack the title and description */
  justify-content: center; /* Center vertically */
  align-items: flex-start; /* Align text to the left */
  color: white; /* White text for contrast */
  max-width: 480px; /* Limit text width */
  padding-left: 24px; /* Add padding for better alignment */
}

.signup-header-text h1 {
  font-size: 36px; /* Larger title font size */
  font-weight: 700; /* Bold title */
  margin-bottom: 12px; /* Spacing below the title */
}

.signup-header-text p {
  font-size: 18px; /* Slightly smaller font for subtitle */
  font-weight: 400; /* Regular weight for the subtitle */
  line-height: 1.6; /* Increase line spacing for readability */
}

/* Header Image */
.onboarding-image {
  flex: 1; /* Allow image to take up remaining space */
  max-width: 400px; /* Restrict the image size */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the available space neatly */
  margin-left: 24px; /* Add spacing between text and image */
}

/* Signup Form Styling */
.signup-form {
  width: 100%;
  min-height: 422px; /* Ensure minimum height for consistent layout */
  flex-shrink: 0;
  border-radius: 0px 0px 20px 20px;
  border: none; /* Remove the border */
  background: white;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  padding: 24px;
  box-sizing: border-box;
  position: relative; /* Necessary for footer positioning */
  overflow: hidden;
  justify-content: space-between; /* Space between content and footer */
}

.linkedin-input{
  color: #AAA;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 200% */
  width: 100%;
}

/* Buttons Inside the Form */
.signup-form .generate-button {
  margin-top: 16px; /* Add spacing above the button */
  width: 242.196px; /* Exact width */
  height: 32px; /* Exact height */
  text-align: center; /* Center text */
  font-size: 14px;
  font-weight: 600; /* Bold font for emphasis */
  border: 1px solid var(--border-grey); /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  padding: 0; /* No extra padding */
  display: flex; /* Flex for aligning content */
  align-self: flex-end; /* Align button to the right of the container */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  transition: transform 0.2s ease; /* Smooth hover effect */
}

.signup-form .generate-button:hover {
  transform: translateY(-2px); /* Subtle hover effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Ensure Responsive Layout */
.signup-form .button-group {
  width: 100%; /* Full width for responsive scaling */
  max-width: 600px; /* Restrict maximum width */
}

.signup-form-footer {
  display: flex;
  justify-content: space-between; /* Space between Step text and buttons */
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid var(--border-grey);
  background-color: white;
  width: 100%; /* Full width of footer */
  box-sizing: border-box;
}

/* Container for Buttons */
.button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 24px; /* Add spacing above the button area */
}

.button-icon {
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */
  margin-right: 8px; /* Space between icon and text */
}

/* Manual Link Button */
.manual-link {
  display: inline-flex; /* Ensures proper alignment */
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.manual-link:hover {
  background-color: var(--theme-color);
  color: white;
}

.previous-button,
.next-button,
.finish-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
  text-decoration: none;
  flex: 1; /* Equal size */
  max-width: 200px; /* Optional: Consistent button width */
}

.previous-button:hover,
.next-button:hover {
  background-color: var(--theme-color-dark); /* Slightly darker on hover */
  transform: translateY(-2px);
}

.signup-form .content-container {
  flex: 1; /* Pushes the footer to the bottom */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  width: 100%;
}

/* Restrict button size in LoginInfo */
#start-signup-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px; /* Standard button height */
  width: 100%; /* Full width of parent container */
  max-width: 432px; /* Optional: Limit max-width */
  padding: 12px 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

#start-signup-button:hover {
  transform: translateY(-2px); /* Subtle hover effect */
}

/* LoginInfo specific input row styling */
.login-input-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align labels and inputs to the left */
  width: 100%; /* Ensure inputs span full width */
  max-width: 432px; /* Restrict the maximum width */
  margin-bottom: 16px; /* Add spacing between rows */
}

.login-input-row .signup-input {
  height: 44px;
  width: 100%; /* Ensure full width within the container */
  padding: 10px;
  border: 1px solid var(--border-grey);
  border-radius: 8px;
  font-size: 14px;
  color: var(--darker-grey);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-input-row .signup-input:focus {
  border-color: var(--theme-color);
  box-shadow: 0 0 8px rgba(124, 118, 245, 0.3);
}

.login-input-row .signup-input::placeholder {
  color: #999;
  font-size: 14px;
}

/* Error message styling specific to this page */
.login-input-row .error-message {
  color: #ff4d4f; /* Subtle red for errors */
  font-size: 12px;
  margin-top: 5px;
  margin-left: 2px;
}

.textarea-input {
  height: 67px; /* Set the height of the textarea */
  flex-shrink: 0; /* Prevent the textarea from shrinking */
  background-color: white; /* Set the fill color to white */
  border: 1px solid #7D828E; /* Add a border with specified color */
  font-family: Arial, sans-serif; /* Set font family */
  resize: none; /* Prevent the user from resizing the textarea */
}

.textarea-input:focus {
  border-color: #6b63ff; /* Change border color on focus */
  box-shadow: 0 0 4px rgba(107, 99, 255, 0.5); /* Add a subtle focus effect */
}

/* Options Container Styling */
.options-container {
  display: flex; /* Flex layout for aligning items */
  flex-wrap: wrap; /* Allow tags to wrap on smaller screens */
  gap: 10px; /* Space between tags */
  padding: 10px; /* Padding around the container */
  background-color: #f9f9fc; /* Light background for contrast */
  border: 1px solid var(--border-grey); /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  max-width: 100%; /* Full width but responsive */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Individual Option Item Styling */
.option-item {
  display: flex; /* Flex for aligning text and button */
  align-items: center; /* Vertically align content */
  background-color: #e0e7ff; /* Light purple background */
  color: #3b82f6; /* Blue text color */
  padding: 6px 12px; /* Internal padding for text */
  border-radius: 20px; /* Rounded pill shape */
  font-size: 14px; /* Font size for readability */
  font-weight: 500; /* Slightly bold text */
  line-height: 1; /* Maintain single-line height */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for better visuals */
  position: relative; /* Position for button alignment */
  cursor: default; /* Disable pointer cursor */
}

/* Remove Button Styling */
.remove-button {
  background: none; /* No background */
  border: none; /* Remove border */
  color: #3b82f6; /* Blue color matching the text */
  font-weight: bold; /* Bold text for emphasis */
  margin-left: 8px; /* Space from the text */
  transition: color 0.3s ease; /* Smooth transition for hover */
}

.remove-button:hover {
  color: #1e3a8a; /* Darker blue on hover */
  background: none;
  transform: scale(1.1); /* Slight zoom effect */
}

/* Focus Styling for Accessibility */
.remove-button:focus {
  outline: none; /* Remove default outline */
  color: #1e3a8a; /* Match hover color */
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle glow effect */
}

/* Responsive Design for Options Container */
@media (max-width: 768px) {
  .options-container {
    padding: 8px; /* Adjust padding for smaller screens */
    gap: 8px; /* Reduce gap between items */
  }

  .option-item {
    font-size: 13px; /* Smaller font for compact view */
    padding: 5px 10px; /* Adjust padding */
  }

  .remove-button {
    font-size: 14px; /* Adjust button size */
  }
}

/* Add Button Styling */
.add-button {
  display: inline-flex; /* Flex layout for centering */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  padding: 8px 16px; /* Padding for better click area */
  font-size: 14px; /* Standard font size */
  font-weight: 600; /* Bold text for emphasis */
  border: none; /* Remove default border */
  border-radius: 8px; /* Rounded corners for a modern look */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.add-button:hover {
  transform: translateY(-2px); /* Subtle hover lift effect */
}

/* Responsive Design for Add Button */
@media (max-width: 768px) {
  .add-button {
    padding: 6px 12px; /* Smaller padding for compact view */
    font-size: 13px; /* Adjust font size */
  }
}

.tone-of-voice-container{
  margin: 20px 0;
}

.tone-options {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
}

.tone-option {
  background: #f3f3f3;
  color: var(--darker-grey);
  border: 2px solid var(--lighter-grey);
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  user-select: none;
}

.tone-option:hover {
  background: #e1e1e1;
  border-color: #bbb;
}

.tone-option.selected {
  background: var(--theme-color-bright);
  color: white;
  border-color: #9b33d6;
  font-weight: 600;
  box-shadow: 0px 4px 8px rgba(188, 67, 255, 0.3);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .tone-options {
    justify-content: center;
  }

  .tone-option {
    padding: 8px 12px;
    font-size: 13px;
  }
}

.input-group-row small {
  display: block;
  margin-top: 5px; /* Optional: Adds spacing */
  color: gray; /* Optional: Style it */
}
