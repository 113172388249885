/* Container styling for the CompetitorsAnalysis component */
.competitors-analysis-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Header styling */
.competitors-analysis-container h1 {
  font-size: 24px;
  color: var(--darker-grey);
  margin-bottom: 20px;
}

/* Styling for input fields */
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  height: 40px;
}

/* Styling for the button */
.fetch-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

/* Analysis data section styling */
.analysis-data {
  margin-top: 20px;
  text-align: left;
}

.analysis-data h2 {
  color: #553c9a;
  margin-bottom: 10px;
}

.analysis-data ul {
  list-style-type: disc;
  padding-left: 20px;
}

.analysis-data li {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}
