/* Container styling for the CompetitorsKeywords component */
.competitors-keywords-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Header styling */
.competitors-keywords-container h1 {
  font-size: 24px;
  color: var(--darker-grey);
  margin-bottom: 20px;
}
