/* Container styling for the CreateTrends component */
.create-trends-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Header styling */
.create-trends-container h1 {
  font-size: 24px;
  color: var(--darker-grey);
  margin-bottom: 20px;
}

/* Error message styling */
.create-trends-container p {
  color: red;
  font-weight: bold;
}

/* Styling for select elements and input fields */
.create-trends-container select,
.country-input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: 1px solid var(--light-grey) !important;
  height: 40px !important;
  line-height: 1.5 !important;
}

/* Ensuring placeholder text is styled consistently */
.country-input::placeholder {
  color: #999 !important;
}

/* Styling for the button */
.create-trends-container button {
  width: 100%;
  padding: 10px;
  background-color: var(--theme-color-dark);
  border: none;
  border-radius: 5px;
}
