.analytics-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.analytics-title {
  text-align: center;
  font-size: 24px;
  color: var(--theme-color-dark);
  margin-bottom: 20px;
}

.analytics-error {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.analytics-loading {
  color: var(--darker-grey);
  text-align: center;
}

.analytics-form {
  display: flex;
  flex-direction: column;
}

.analytics-label {
  font-size: 16px;
  color: var(--darker-grey);
  margin-top: 10px;
}

.analytics-select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--light-grey);
  margin-top: 5px;
}

.analytics-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.analytics-date-input {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.analytics-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 16px;
  flex: none;
}

.analytics-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--theme-color-dark);
  border-radius: 50%;
  margin: 20px auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Adds spacing between the buttons */
  margin-bottom: 20px; /* Adds spacing below the buttons */
}

.logout-button {
  background-color: #ff4d4d;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  flex: none; /* Ensures buttons don't stretch */
}

.logout-button:hover {
  background-color: #ff6666;
}
