.prospect-image {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
}

.prospect-placeholder {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--light-grey); /* Grey circle for missing images */
}

.table-text {
  color: black;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.input-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.add-prospect-button {
  margin: 10px;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
}

.prospect-input,
.search-input {
  max-width: 400px;
  margin: 10px 0;
  font-size: 16px;
}
